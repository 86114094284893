import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, finalize, map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Category, CategoryPreview, CategoryPreviewApiResponse, CreateCategoryRequestParams, CreateCategoryResponse } from 'src/models/category.model';
import { ApiDataResponseMessage, ApiDataResponseObject } from '@root/models/global-interfaces';

@Injectable()
export class CategoryService {
  public apiUrl = environment.apiUrl;

  constructor(private http: HttpClient) {}

  public createCategory (
    body: CreateCategoryRequestParams,
  ): Observable<CreateCategoryResponse> {
    return this.http.post(`${this.apiUrl}topics`, body).pipe(
      map((res: CreateCategoryResponse) => {
        return res;
      }),
      catchError((error) => {
        return throwError(() => error);
      }),
    );
  };

  public getCategories (
  ): Observable<CategoryPreview[]> {
    return this.http
      .get(`${this.apiUrl}topics`)
      .pipe(
        map((res: CategoryPreview[]) => {
          return res;
        }),
        catchError((error) => {
          return throwError(() => error);
        }),
        finalize(() => {}),
      );
  };

  public updateCategories(categoryArray: CategoryPreviewApiResponse[]): Observable<Category> {
    return this.http
      .post(`${this.apiUrl}topics/bulk-update`, { categoryArray })
      .pipe(
        map((res: Category) => {
          return res;
        }),
        catchError((error) => {
          return throwError(() => error);
        }),
      );
  };

  public deleteCategory(categoryID: number): Observable<ApiDataResponseMessage> {
    return this.http.delete(`${this.apiUrl}topics/${categoryID}`).pipe(
      map((res: ApiDataResponseObject<ApiDataResponseMessage>) => {
        return res.data;
      }),
      catchError((error) => {
        return throwError(() => error);
      }),
    );
  };

  public searchCategories (
    categories: Category[],
    searchTerm: string,
    expand: boolean = true,
  ): Category[] {
    const lowerCaseSearchTerm: string = searchTerm.toLowerCase();
    const filteredCategories: Category[] = categories.filter((cat) => {
      const normalizedTitle:string = cat.topicTitle.toLowerCase();
      return normalizedTitle.includes(lowerCaseSearchTerm);
    });

    if (searchTerm.length === 0) {
      return filteredCategories;
    }

    const outputTree: Category[] = [];

    filteredCategories.forEach((category: Category) => {
      let parent: Category = categories.find(
        (cat) => cat.topicID === category.topicParentID,
      );

      const exists: Category = outputTree.find((cat) => cat.topicID === category.topicID);
      while (parent) {
        const parentExists: Category = outputTree.find(
          (cat) => cat.topicID === parent?.topicID,
        );
        if (!parentExists) {
          outputTree.push(parent);
        }
        if (expand) {
          parent.isExpanded = true;
        }
        parent = categories.find(
          (cat) => cat.topicID === parent?.topicParentID,
        );
      }
      if (!exists) {
        outputTree.push(category);
      }
    });

    return outputTree;
  };
}
