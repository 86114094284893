import { DestroyRef, Injectable, inject } from '@angular/core';
import { UserService } from '@root/services/user-service/user.service';
import { GeneralStore } from '@root/store/general.store';
import { LocalStoreService } from './local-store.service';
import { AssignedLocation } from '@root/store/location.store';
import { GlobalPermissions, LocalPermissions } from '@root/models/global-enum';

@Injectable({
  providedIn: 'root',
})
export class PermissionsService {
  /* eslint-disable @typescript-eslint/no-explicit-any */
  userLocationAllCreds: any;
  userType!: string;
  destroyRef = inject(DestroyRef);
  localStorage = inject(LocalStoreService);
  userLocationsProfileCreds: any = undefined;

  readonly userService = inject(UserService);
  readonly generalStore = inject(GeneralStore);

  public constructor() {
  }

  public checkRoutePermissions(atLeastOneGlobalPermissions: number[], locationId: number, atLeastOneLocalPermissions: number[], checkTaskboardPermissions: boolean = false): boolean {
    let permissableLocation: boolean = false;
    if(checkTaskboardPermissions) {
      if(!this.generalStore.isTaskBoardUser()) {
        return false;
      }
    }
    if (locationId) {
      const foundLocation: AssignedLocation = this.generalStore.assignedLocations()
        .find((location: AssignedLocation) => location.locationID === locationId);
      if (foundLocation) {
        if (atLeastOneLocalPermissions && atLeastOneLocalPermissions.length > 0 ) {
          if (this.generalStore.globalCreds().indexOf(GlobalPermissions.GLOBAL_ADMIN) >= 0 ||
            this.generalStore.globalCreds().indexOf(GlobalPermissions.GLOBAL_VIEWER) >= 0) {
            permissableLocation = true;
          }
          else {
            const locationPermissions: number[] = this.generalStore.locationCreds().get(foundLocation.locationID);
            if (locationPermissions) {
              permissableLocation = false;
              atLeastOneLocalPermissions.forEach((neededPermission: number) => {
                if (locationPermissions.indexOf(neededPermission) >= 0) {
                  permissableLocation = true;
                }
              });
            }
            else {
              permissableLocation = false;
            }
          }
        }
        else {
          permissableLocation = true;
        }
      } 
      else {
        return false;
      }
    }
    else {
      permissableLocation = true;
    }

    let hasPermission: boolean = false;
    if (!atLeastOneGlobalPermissions || atLeastOneGlobalPermissions.length === 0) {
      hasPermission = true;
    }
    else {
      const foundPermissions: number[] = atLeastOneGlobalPermissions
        .filter(neededPermission => this.generalStore.globalCreds()
          .some((currentPermission: number) => neededPermission === currentPermission));
      hasPermission = foundPermissions && foundPermissions.length > 0;
    }

    return permissableLocation && hasPermission;
  }

  public checkLocalPermission(locationId: number, permission: LocalPermissions): boolean {
    return this.generalStore.locationCreds().get(locationId)?.indexOf(permission) >= 0;
  }

  public checkGlobalPermission(permission: GlobalPermissions): boolean {
    if (this.generalStore.globalCreds().indexOf(permission) >= 0) {
      return true
    } else { 
      return false
    }
  }

  public checkViewOnly(locationId: number) {
    const locationCreds: number[] = this.generalStore.locationCreds().get(locationId);
    if(this.generalStore.globalCreds().indexOf(GlobalPermissions.GLOBAL_ADMIN) >= 0) {
      return false
    }
    else if(this.generalStore.locationCreds().get(locationId)?.indexOf(LocalPermissions.LOCAL_ADMIN) >= 0) {
      return false
    }
    else if(this.generalStore.globalCreds().indexOf(GlobalPermissions.GLOBAL_VIEWER) >= 0) {
      return true
    }
    else if(!locationCreds) {
      return false;
    }
    else {
      return locationCreds.includes(LocalPermissions.LOCAL_VIEWER)
    }
  }
}
